<template>
  <div class="avatar">
    <el-popover ref="popover" placement="right" title="" width="500" trigger="hover">
      <img class="vd_popimg" :src="imgeUrl" />
    </el-popover>
    <div v-popover:popover class="avatar">
      <img :src="imgeUrl" class="avatar" @error="imgOnError" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    imgeUrl: { type: String },
    imgeUrlO: { type: String }
  },
  methods: {
    imgOnError(e) {
      let img = e.srcElement;
      img.src = '';
      img.onerror = null; //防止闪图
    }
  }
};
</script>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 100% !important;
  height: 100% !important;
}
.avatar div {
  width: 100% !important;
  height: 100% !important;
}
.vd_popimg {
  width: 500px;
  height: 500px;
}
</style>
